import React from 'react'
import Link from 'gatsby-link';

const NotFoundPage = () => (
  <div className='container'>
    <h1>Whoops!</h1>
    <p>This page doesn't exist. <Link to='/'>Go to the homepage</Link></p>
  </div>
)

export default NotFoundPage
